<template>
  <div class="vx-row">
    <div class="vx-col w-full mb-base" v-if="is_filter">
      <vx-card title="Filter">
        <div class="vx-row mb-3">
          <div class="vx-col lg:w-1/3 md:w-1/2 sm:w-3/4 w-full">
                <small class="ml-2">Period</small>
                <div class='flex align-items-center'>
                    <flat-pickr :config="flatPickrConfig" class="w-full" v-model="filterData.date_start" @input='getData()'></flat-pickr>
                    <small class='ml-5 mr-5'>Until</small>
                    <flat-pickr :config="flatPickrConfig" class="w-full" v-model="filterData.date_end"  @input='getData()'></flat-pickr>
                </div>
          </div>
        </div>
        <div class="vx-col w-full flex mt-3">
          <vs-button @click="exportExcel()" color="success">Export Excel</vs-button>&nbsp;
          <vs-button color="warning" @click="resetFilter()">Reset</vs-button>
        </div>
      </vx-card>
    </div>
    <div class="vx-col w-full mb-base">
      <vx-card title="Car Shipments">
        <vs-table :sst="true" search @search="handleSearch" :data="data.data" @sort="handleSort" class="mb-3" >
          <template slot="header">
            <vs-button color="primary" @click="is_filter = !is_filter">Filter</vs-button>
          </template>
          <template slot="thead">
            <vs-th sort-key="date">Date Shipment</vs-th>
            <vs-th sort-key="job_order_code">Job Order No.</vs-th>
            <vs-th sort-key="shipment_code">Shipment No.</vs-th>
            <vs-th sort-key="customer">Customer Name</vs-th>
            <vs-th sort-key="no_faktur">No Faktur</vs-th>
            <vs-th sort-key="faktur_date">Faktur Date</vs-th>
            <vs-th sort-key="no_machine">No Machine</vs-th>
            <vs-th sort-key="no_chasis">No Chasis</vs-th>
            <vs-th sort-key="interior">Interior</vs-th>
            <vs-th sort-key="car_factory">Car Factory</vs-th>
            <vs-th sort-key="car_type">Car Type</vs-th>
            <vs-th sort-key="car_color">Exterior</vs-th>
            <vs-th sort-key="qty">Quantity</vs-th>
            <vs-th sort-key="sender_name">Shipper</vs-th>
            <vs-th sort-key="receiver_name">Consignee</vs-th>
            <vs-th sort-key="email_received">Email Received</vs-th>
            <vs-th sort-key="sipb_received">SIPB Received</vs-th>
            <vs-th sort-key="delivery_status_name">Status</vs-th>
            <vs-th sort-key="shipment_start_time">Start Delivery At</vs-th>
            <vs-th sort-key="shipment_end_time">Finish Delivery At</vs-th>
            <vs-th sort-key="shipment_duration">Shipment Duration (Minutes)</vs-th>
            <vs-th sort-key="job_duration">Job Duration (Minutes)</vs-th>
          </template>
          <template slot-scope="{data}">
            <vs-tr :key="indextr" v-for="(tr, indextr) in data">
              <vs-td :data="tr.date">{{tr.date}}</vs-td>
              <vs-td label="Job Order No." :data="tr.job_order_code">{{tr.job_order_code}}</vs-td>
              <vs-td label="Shipment No." :data="tr.shipment_code">{{tr.shipment_code}}</vs-td>
              <vs-td label="Customer Name" :data="tr.customer">{{tr.customer}}</vs-td>
              <vs-td label="No Faktur" :data="tr.no_faktur">{{tr.no_faktur}}</vs-td>
              <vs-td label="Faktur Date" :data="tr.faktur_date">{{tr.faktur_date}}</vs-td>
              <vs-td label="No Machine" :data="tr.no_machine">{{tr.no_machine}}</vs-td>
              <vs-td label="No Chasis" :data="tr.no_chasis">{{tr.no_chasis}}</vs-td>
              <vs-td label="Interior" :data="tr.interior">{{tr.interior}}</vs-td>
              <vs-td label="Car Factory" :data="tr.car_factory">{{tr.car_factory}}</vs-td>
              <vs-td label="Car Type" :data="tr.car_type">{{tr.car_type}}</vs-td>
              <vs-td label="Exterior" :data="tr.car_color">{{tr.car_color}}</vs-td>
              <vs-td label="Quantity" class="text-right" :data="tr.qty">{{tr.qty}}</vs-td>
              <vs-td label="Shipper" :data="tr.sender_name">{{tr.sender_name}}</vs-td>
              <vs-td label="Consignee" :data="tr.receiver_name">{{tr.receiver_name}}</vs-td>
              <vs-td label="Email Received" :data="tr.email_received">{{tr.email_received}}</vs-td>
              <vs-td label="SIPB Received" :data="tr.sipb_received">{{tr.sipb_received}}</vs-td>
              <vs-td label="Status" :data="tr.delivery_status_name">{{tr.delivery_status_name}}</vs-td>
              <vs-td label="Start Delivery At" :data="tr.shipment_start_time">{{tr.shipment_start_time}}</vs-td>
              <vs-td label="Finish Delivery At" :data="tr.shipment_end_time">{{tr.shipment_end_time}}</vs-td>
              <vs-td label="Shipment Duration" :data="tr.shipment_duration">{{tr.shipment_duration}}</vs-td>
              <vs-td label="Shipment Duration" :data="tr.job_duration">{{tr.job_duration}}</vs-td>
            </vs-tr>
          </template>
        </vs-table>
        <vs-pagination :total="data.last_page" v-model="currentPage" class="mt-5"></vs-pagination>
      </vx-card>
    </div>
  </div>
</template>

<script>
import {mapActions, mapState} from 'vuex'
import flatPickr from 'vue-flatpickr-component';
import 'flatpickr/dist/flatpickr.css';
import axios from '@/axios'
export default {
  components:{
    flatPickr
  },
  data(){
    return {
      filterData : {},
      is_filter : null,
      idDelete: null,
      currentPage: 1,
      search:null,
      sortKey:null,
      sortVal:null,
      flatPickrConfig: {
            altFormat: 'j M Y',
            altInput: true,
            dateFormat: 'Y-m-d',
      }
    }
  },
  watch:{
    search(){
      this.currentPage=1
      this.getData()
    },
    currentPage(){
      this.getData()
    }
  },
  computed:{
    ...mapState({
      data: state => state.operational_reports.rows
    })
  },
  methods:{
    ...mapActions({
      dispatchIndex: 'operational_reports/car_shipments',
    }),
    async getData(){
      let payload = {
        page: this.currentPage,
        search: this.search,
        sortKey: this.sortKey,
        sortVal: this.sortVal
      }
      payload = Object.assign(payload, this.filterData)
      await this.dispatchIndex(payload)
    },
    async confirmDelete(){
      try {
        await this.dispatchDestroy(this.idDelete)
        this.getData()
        this.$vs.notify({
          title: `Success`,
          text: `Your data has been deleted successfully`,
          color: `primary`
        })
      } catch (error) {
        this.$vs.notify({
          title: `Oops!`,
          text: `Looks like something went wrong. please try again later (${error.data.message})`,
          color: `danger`
        })
      }
    },
    deletes(id){
      this.idDelete = id
      this.$vs.dialog({
        type: `confirm`,
        color: `danger`,
        title: `Are you sure ?`,
        text: `Deleted data can no longer be restored`,
        accept: this.confirmDelete
      })
    },
    handleSearch(val){
      this.search = val
    },
    handleSort(key,val){
      this.sortKey = key
      this.sortVal = val
      this.getData()
    },
    async exportExcel(){
      await axios.post(`operational/exports/car_shipments`, this.filterData, {
            responseType: 'blob'
        }).then((response) => {
            const link = document.createElement('a');
            link.href = window.URL.createObjectURL(
                new Blob([response.data])
            );
            link.setAttribute('download', 'Car Shipment.xlsx');
            document.body.appendChild(link);
            link.click();
      });
    },
    async resetFilter() {
      this.filterData = {}
      await this.getData()
    }
  },
  async mounted(){
    await this.getData()
  }
}
</script>

<style>

</style>